<template>
    <div class="d-flex flex-column justify-center align-center fill-height py-8 overflow-hidden">
        <v-card width="350">
            <v-card-title class="justify-center align-center nuvolos-header">
                <v-img max-width="200" max-height="22" class="py-5" src="@/assets/Nuvolos-transparent.svg" />
            </v-card-title>
            <h2 class="secondary--text text-center text-h6 mt-5">Accepting invitation</h2>
            <v-card-text>
                This might take a few seconds.
                <br />
                Please bear with us until we're done.
            </v-card-text>
            <v-card-text class="text-center pb-16">
                <dashboard-error v-if="error" :msg="errorMsg" />
                <template v-else>
                    <v-progress-linear stream buffer-value="0" v-model="progress" color="primary" rounded height="6"></v-progress-linear>
                </template>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
const DashboardError = () => import('@/components/DashboardError.vue')

export default {
    components: { DashboardError },
    data() {
        return {
            error: null,
            errorMsg: '',
            progress: 0
        }
    },
    created() {
        this.acceptInvite()
    },
    methods: {
        async acceptInvite() {
            try {
                const { data } = await this.$axios.get(`/invitations/accept/${this.$route.params.invid}`)
                let route = {}
                if (data.landing_page) {
                    const overviewURI = data.landing_page.split('nuvolos.cloud').pop()
                    const dashboardOid = overviewURI.split('/')[2]
                    route = { name: 'home-dashboard', params: { oid: dashboardOid }, query: { invitation_target: overviewURI } }
                } else {
                    route = { name: 'welcome' }
                }
                this.progress = 100
                await new Promise(resolve => setTimeout(resolve, 800))
                this.$router.push(route)

                // Get new orgs in case invite added a new org
                this.$store.dispatch('fetchUserOrgs')
            } catch (error) {
                if (!error.response || !error.data || error.data.code !== 'already_accepting') {
                    this.errorMsg = 'Failed to accept the invitation.'
                }
                if (error?.response?.data?.code === 'max_accepts_reached') {
                    this.errorMsg = 'Invitation was already accepted!'
                }
                this.error = error
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/sass/signup.scss';
</style>
